@cl-white: #FFFFFF;
@cl-gray: #b0b2b6;
@cl-orange: #e45914;
@cl_gray_dark: #42444d;
@cl_black:#242732;


.border-radius(@radom){
  border-radius: @radom;
  -webkit-border-radius: @radom;
  -moz-border-radius: @radom;
  -ms-border-radius: @radom;
  -o-border-radius: @radom;
}
.transfrom(@radom){
  -ms-transform: @radom; /* IE 9 */
  -webkit-transform: @radom; /* Safari */
  transform: @radom; /* Standard syntax */
}

.transition(@radom){
  transition: all @radom linear 0s;
  -moz-transition: all @radom linear 0s;
  -webkit-transition: all @radom linear 0s;
  -o-transition: all @radom linear 0s;
}

